<template>
    <div>
        <navheader></navheader>
        <!-- <videoplay :playbox="playbox"></videoplay> -->
        <div class="in-player-panel">
            <div class="in-player"
                :id="playerId"></div>
        </div>
        <navfooter></navfooter>
    </div>
</template>

<script>
import navheader from '../components/NavHeader'
// import videoplay from '../components/video';
import navfooter from '../components/NavFooter'
// aliplayer h5 格式播放器的 js 地址
const jsPath = 'https://g.alicdn.com/de/prismplayer/2.8.1/aliplayer-h5-min.js'
// aliplayer h5 格式播放器的 css 地址
const cssPath = 'https://g.alicdn.com/de/prismplayer/2.8.1/skins/default/aliplayer-min.css'
// 视频的长宽比例，采用 width / height 得到，基本按照 16:9 获取
const ratio = 1.78
export default {
    name: 'Video',
    data() {
        return {
            query: this.$route.query,
            videoPlay: '',
            videoVid: '',
            // 默认宽度
            defaultWidth: 1200,
            // 默认高度
            defaultHeight: 600,
            // 每个播放器都具有单独的 id
            playerId: 'inPlayer' + Math.random() * 100000000000000000,
            // 通过 vid 从后端获取到的鉴权地址
            videoAuth: '',
            // 播放器实例
            inPlayer: null,
            exitTime: 0,
            oldTime: 0
        }
    },
    metaInfo: {
        title: '视频播放-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    computed: {
      // 计算后得到的真实宽度
      realWidth () {
        // 如果从外部传入了高度，则按照外部高度乘以宽高比，获取真实宽度，否则按照默认高度计算
        return `${(this.height || this.defaultHeight) * ratio}px`
      },
      // 计算后得到真实高度
      realHeight () {
        // 如果从外部传入了宽度，则按照外部宽度乘以宽高比，获取真实高度，否则按照默认宽度计算
        return `${(this.width || this.defaultWidth) / ratio}px`
      }
    },
    mounted() {
        this.getClass();
        this.countVideoTime();
    },
    destroyed() {
      this.exitPage();
    },
    components: {
        navheader,
        // videoplay,
        navfooter
    },
    methods: {
        // 获取直播
        getClass() {
            let courseContentId = this.query.courseContentId;
            let id = this.query.courseId;
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/course/video/play_info.html',
                data: {
                    courseId: id,
                    courseContentId: courseContentId
                }
            }).then((res) => {
                // console.log(res,"视频")
                this.videoPlay = res.data.result.vodUrlHls;
                this.videoVid = res.data.result.vodVideoId;
                this.videoAuth = res.data.result.vodPlayAuth;
                this._initialize()

            })

        },
        // 初始化
      _initialize () {
        // 判断播放器全局实例是否已存在，只有当播放器依赖的 JS 加载完成后，播放器的全局实例才存在
        if (!window.Aliplayer) {
          // 尝试获取播放器的 JS 标签
          let inPlayerScriptTag = document.getElementById('inPlayerScriptTag')

          // 判断JS是否已存在
          if (!inPlayerScriptTag) {
            // 不存在则创建 JS 标签
            inPlayerScriptTag = document.createElement('script')
            inPlayerScriptTag.type = 'text/javascript'
            // 指定 JS 的地址
            inPlayerScriptTag.src = jsPath
            // 指定 JS 的 ID
            inPlayerScriptTag.id = 'inPlayerScriptTag'

            // JS 不存在说明 CSS 也不存在，则创建 CSS 标签
            let inPlayerLinkTag = document.createElement('link')
            inPlayerLinkTag.type = 'text/css'
            inPlayerLinkTag.rel = 'stylesheet'
            // 指定 CSS 的地址
            inPlayerLinkTag.href = cssPath

            // 获取页面的 <head> 标签
            let head = document.getElementsByTagName('head')[0]
            // 将 JS 和 CSS 插入到 DOM 中
            head.appendChild(inPlayerScriptTag)
            head.appendChild(inPlayerLinkTag)
          }

          // JS 插入并加载完成
          if (inPlayerScriptTag.loaded) {
            // 初始化播放器
            this._initPlayer()
          } else {
            // JS 没有加载完成，则监听 JS 的加载事件
            inPlayerScriptTag.addEventListener('load', () => {
              // JS 确认加载完成后，初始化播放器
              this._initPlayer()
            })
          }
        } else {
          // 全局实例存在则直接初始化播放器
          this._initPlayer()
        }
      },
      // 初始化播放器
      _initPlayer () {
        // 确保 DOM 元素都已经渲染完毕
        this.$nextTick(() => {
          // 判断播放器实例是否存在
          if (!this.inPlayer) {
            //   console.log(this.videoPlay,"播放器已生成")
            // 初始化播放器
            this.inPlayer = window.Aliplayer({
            // 播放器 ID
            id: this.playerId,
            source: this.videoPlay,
            // 视频的 VID
            vid: this.videoVid,
            // // 鉴权地址
            playauth: this.videoAuth,
            // 宽度
            width: this.realWidth,
            // 高度
            height: this.realHeight,
            // 使用 H5 格式
            useH5Prism: true,
            // 不是直播 
            isLive: false,
            // 不自动播放
            autoplay: false
            })
            // this.inPlayer.on("play",()=>{
            //   let currentTime = this.inPlayer.getCurrentTime()
            //   this.exitTime = currentTime;
            //   console.log(this.exitTime)
            // })
            // this.inPlayer.on("play", () => {
            //     let courseContentId = this.query.courseContentId;
            //     let id = this.query.courseId;
            //     this.axios({
            //       method: 'POST',
            //       url: 'api/v2/yun/course/video/start.html',
            //       data: {
            //           courseId: id,
            //           courseContentId: courseContentId
            //       }
            //   }).then((res) => {
            //       console.log(res,"视频")
            //   })
            // });
            // this.inPlayer.on("pause", () => {
            //   let currentTime = this.inPlayer.getCurrentTime();
            //   this.exitTime = currentTime;
              
            //   this.exitPage()
            // })
          } else {
            // 存在播放器实例则先销毁组件
            this.inPlayer.dispose()
            // 将播放器实例重置
            this.inPlayer = null
            // 清空播放器 DOM 内容
            document.getElementById(this.playerId).innerHTML = ''
            // 再次初始化
            this._initPlayer()
          }
        })
      },
      exitPage() {
        let courseContentId = this.query.courseContentId;
        let id = this.query.courseId;
        let currentTime = this.inPlayer.getCurrentTime();
        this.axios({
            method: 'POST',
            url: 'api/v2/yun/course/video/exit.html',
            data: {
                courseId: id,
                courseContentId: courseContentId,
                time: currentTime
            }
        }).then(() => {
        })
      },
      countVideoTime() {
        let that = this;
        let courseContentId = that.query.courseContentId;
        let id = that.query.courseId;
        let timer = setInterval(function(){
        let currentTime = that.inPlayer.getCurrentTime();
        if(currentTime != that.oldTime) {
          that.axios({
              method: 'POST',
              url: 'api/v2/yun/course/video/update_play.html',
              data: {
                  courseId: id,
                  courseContentId: courseContentId,
                  time: currentTime
              }
          }).then((res) => {
              that.oldTime = res.data.result.time;
          })
        } 
        },30000);
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        that.$once("hook:beforeDestroy", () => {
            clearInterval(timer);
            // sessionStorage.removeItem('validTime');
        });
      }
    }
}
</script>
<style lang="scss">
    .in-player-panel {
        padding-top: 90px;
        padding-bottom: 52px;
        box-sizing: border-box;
        width: 100%;
        height: 800px;
        min-width: 1200px;
        background: #222222;
        .in-player {
            margin: 0 auto;
            width: 1200px;
        }
    }
</style>
